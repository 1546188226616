import { create } from 'zustand';
import { api } from './authStore';
import { toast } from 'sonner';
import { CurrentPhoto } from '@/types/observation';
import { nanoid } from 'nanoid';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';
import { Photo } from '@/types/media';

export const PHOTO_ALIASES = ['files', 'photos']

interface PhotoState {
  photos: Photo[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;
  currentPhotos: CurrentPhoto[];

  setSearchQuery: (query: string) => void;
  fetchPhotos: (page?: number, search?: string) => Promise<void>;
  addPhotos: (photoFiles: FileList | null) => Promise<void>;
  removePhoto: (photoId: string) => void;
  uploadPhoto: (photoId: string, file: File) => Promise<void>;
  regenerateSummary: (remotePhotoId: number) => void;
  regeneratePhotoSummary: (remotePhotoId: number) => void;
  retry: () => Promise<void>;
}

export const usePhotoStore = create<PhotoState>((set, get) => {

  const getBaseDomainUrl = async (): Promise<string> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return `/protected/${authStore.userDomainConfig.urlPhoto}`;
  };

  return {
    photos: [],
    isLoading: false,
    error: null,
    searchQuery: '',
    searchDebounceTimeout: null,
    currentPhotos: [],

    setSearchQuery: (query) => {
      set({ searchQuery: query });
      const searchDebounceTimeout = get().searchDebounceTimeout;
      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      const timeout = setTimeout(() => {
        get().fetchPhotos(1, query);
      }, 300);
      set({ searchDebounceTimeout: timeout });
    },

    fetchPhotos: async () => {
      set({ isLoading: true, error: null });
      try {
        const { searchQuery } = get();
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/list`, {
          params: {
            query: searchQuery,
          },
        });
        set({
          photos: response.data.photos,
          isLoading: false,
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Photos';
        set({
          error: errorMessage,
          isLoading: false,
        });
        toast.error(errorMessage);
      }
    },

    addPhotos: async (files) => {
      if (files) {
        const newPhotos = Array.from(files).map((file) => ({
          id: nanoid(10),
          file: file,
          llm_summary: null,
          url: URL.createObjectURL(file),
          remoteId: null,
          failed: false,
          uploaded_at: new Date()
        })) as CurrentPhoto[];
        set((state) => ({
          currentPhotos: [...state.currentPhotos, ...newPhotos]
        }));
        await Promise.all(newPhotos.map((photo) => get().uploadPhoto(photo.id, photo.file)));
      }
    },

    removePhoto: (photoId) =>
      set((state) => ({
        currentPhotos: state.currentPhotos.filter((p) => p.id !== photoId)
      })),

    uploadPhoto: async (photoId, file) => {
      try {
        const formData = new FormData();
        formData.append('photo', file);
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          maxContentLength: 1000000000,
          maxBodyLength: 1000000000
        });

        set((state) => ({
          currentPhotos: state.currentPhotos.map((p) =>
            p.id === photoId
              ? {
                  ...p,
                  remoteId: response.data.photo_id,
                  llm_summary: response.data.llm_summary,
                  failed: false
                }
              : p
          )
        }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set((state) => ({
          currentPhotos: state.currentPhotos.map((p) => (p.id === photoId ? { ...p, failed: true } : p))
        }));
        toast(`Error uploading photo ${photoId}`);
      }
    },

    regenerateSummary: async (remotePhotoId) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${remotePhotoId}/regenerateSummary`);
        set((state) => ({
          currentPhotos: state.currentPhotos.map((p) =>
            p.remoteId === remotePhotoId
              ? {
                  ...p,
                  llm_summary: response.data.llm_summary,
                  failed: false
                }
              : p
          )
        }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set((state) => ({
          currentPhotos: state.currentPhotos.map((p) => (p.remoteId === remotePhotoId ? { ...p, failed: true } : p))
        }));
        toast(`Error regenerating summary for photo ${remotePhotoId}`);
      }
    },

    regeneratePhotoSummary: async (photoId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${photoId}/regenerateSummary`);
        // TODO: use a map
        set((state) => ({
          photos: state.photos.map((p) => (p.id === photoId ? { ...p, llm_summary: response.data.llm_summary } : p))
        }));
      } catch (error) {
        toast(`Error regenerating photo`);
      }
    },

    retry: async () => await get().fetchPhotos(),

  }
});

export const useFilteredPhotos = () => {
  const { photos } = usePhotoStore();
  const { filters } = useSearchStore();
  
  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return photos.filter((photo) => {
      const description = photo.llm_summary?.toLowerCase() ?? '';
      const createdAt = new Date(photo.uploaded_at);

      if (filterTab && filterTab !== '' && !PHOTO_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && description.includes(lowerExclude)) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [photos, filters]); 
};