const createAudioBuffer = (originalBuffer: AudioBuffer): AudioBuffer => {
  const newBuffer = new AudioBuffer({
    length: originalBuffer.length,
    numberOfChannels: originalBuffer.numberOfChannels,
    sampleRate: originalBuffer.sampleRate,
  });
  for (let ch = 0; ch < originalBuffer.numberOfChannels; ch++) {
    newBuffer.copyToChannel(originalBuffer.getChannelData(ch).slice(0), ch);
  }
  return newBuffer;
};

const decodeBlob = async (blob: Blob, audioContext: AudioContext): Promise<AudioBuffer> => {
  const arrayBuffer = await blob.arrayBuffer();
  return new Promise((resolve, reject) => {
    audioContext.decodeAudioData(arrayBuffer, (decodedBuffer) => {
      const copiedBuffer = new AudioBuffer({
        length: decodedBuffer.length,
        numberOfChannels: decodedBuffer.numberOfChannels,
        sampleRate: decodedBuffer.sampleRate,
      });
      for (let ch = 0; ch < decodedBuffer.numberOfChannels; ch++) {
        copiedBuffer.copyToChannel(decodedBuffer.getChannelData(ch), ch);
      }
      resolve(copiedBuffer);
    }, reject);
  });
};

const extractChannelData = (audioBuffer: AudioBuffer) => {
  const numChannels = audioBuffer.numberOfChannels;
  const numSamples = audioBuffer.length;
  let extractedData = new Float32Array(numSamples * numChannels);
  for (let ch = 0; ch < numChannels; ch++) {
    const channelData = audioBuffer.getChannelData(ch);
    extractedData.set(channelData, ch * numSamples);
  }
  return extractedData;
};

export async function audioBlob(
  blob: Blob,
  mimeType: string = "audio/mp4"
): Promise<Blob> {
  const audioContext = new AudioContext();
  if (audioContext.state === "suspended") {
    await audioContext.resume();
  }
  let [buffer] = await Promise.all([decodeBlob(blob, audioContext)]);
  buffer = createAudioBuffer(buffer);
  const sampleRate = buffer.sampleRate;
  const data = extractChannelData(buffer);
  return encodeAudio(data, sampleRate, mimeType);
};

export async function combineAudioBlobs(
  firstBlob: Blob,
  firstBlobEnd: number,
  secondBlob: Blob,
  mimeType: string = "audio/mp4"
): Promise<Blob> {
  const audioContext = new AudioContext();
  if (audioContext.state === "suspended") {
    await audioContext.resume();
  }
  let [firstBuffer, secondBuffer] = await Promise.all([
    decodeBlob(firstBlob, audioContext),
    decodeBlob(secondBlob, audioContext),
  ]);
  firstBuffer = createAudioBuffer(firstBuffer);
  secondBuffer = createAudioBuffer(secondBuffer);
  const sampleRate = firstBuffer.sampleRate;
  const trimSamples = Math.min(Math.floor(firstBlobEnd * sampleRate), firstBuffer.length);
  const trimmedFirstData = firstBuffer.getChannelData(0).slice(0, trimSamples);
  const secondData = extractChannelData(secondBuffer);
  const combinedData = new Float32Array(trimmedFirstData.length + secondData.length);
  combinedData.set(trimmedFirstData, 0);
  combinedData.set(secondData, trimmedFirstData.length);
  return encodeAudio(combinedData, sampleRate, mimeType);
}

async function encodeAudio(
  interleaved: Float32Array, 
  sampleRate: number, 
  mimeType: string
): Promise<Blob> {
  const wavBlob = encodeWAV(interleaved, sampleRate);
  return new Blob([wavBlob], { type: mimeType });
}

function encodeWAV(interleaved: Float32Array, sampleRate: number): ArrayBuffer {
  const numChannels = 1;
  const bytesPerSample = 2;
  const bufferSize = 44 + interleaved.length * bytesPerSample;
  const buffer = new ArrayBuffer(bufferSize);
  const view = new DataView(buffer);
  let offset = 0;
  const writeString = (str: string) => {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset + i, str.charCodeAt(i));
    }
    offset += str.length;
  };
  writeString("RIFF");
  view.setUint32(offset, bufferSize - 8, true);
  offset += 4;
  writeString("WAVE");
  writeString("fmt ");
  view.setUint32(offset, 16, true);
  offset += 4;
  view.setUint16(offset, 1, true);
  offset += 2;
  view.setUint16(offset, numChannels, true);
  offset += 2;
  view.setUint32(offset, sampleRate, true);
  offset += 4;
  view.setUint32(offset, sampleRate * numChannels * bytesPerSample, true);
  offset += 4;
  view.setUint16(offset, numChannels * bytesPerSample, true);
  offset += 2;
  view.setUint16(offset, bytesPerSample * 8, true);
  offset += 2;
  writeString("data");
  view.setUint32(offset, interleaved.length * bytesPerSample, true);
  offset += 4;
  for (let i = 0; i < interleaved.length; i++) {
    let sample = Math.max(-1, Math.min(1, interleaved[i]));
    sample = Math.round(sample * 32767); // Correct scaling
    view.setInt16(offset, sample, true);
    offset += 2;
  }
  return buffer;
}
