import { Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from '@/components/ui/breadcrumb';
import { useSelectedItemStore } from '@/store/selectedItemStore';

export function ExploreNavBreadcrumbs() { 
  const location = useLocation();
  const section = location.pathname.split('/')[2].replace(/^\w/, (c) => c.toUpperCase());
  const navigate = useNavigate();
  const { clearSelectedItems } = useSelectedItemStore();
  const { state } = useLocation();

  return (
    <div className="flex items-center justify-between border-b p-1 lg:px-4 lg:pl-2 bg-stone-50">
      <div className="flex items-center">
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={() => {
            navigate('/');
            clearSelectedItems()
          }}
        >
          <ArrowLeft className="h-4 w-4" />
          <span className="sr-only">Back to list</span>
        </Button>
        {state?.from ? (
          <Breadcrumb>
            <BreadcrumbList>
              {state.from.slice(0, -1).map((from: string, index: number) => (
                <Fragment key={index}>
                  <BreadcrumbLink asChild>
                    <span 
                      className="cursor-pointer" 
                      onClick={() => {
                        navigate(-(state.from.length - index - 1))
                        clearSelectedItems();
                      }}
                    >
                      {from}
                    </span>
                  </BreadcrumbLink>
                  <BreadcrumbSeparator />
                </Fragment>
              ))}
              <BreadcrumbItem>{state.from[state.from.length - 1]}</BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
        ) : (
          <Breadcrumb>
            <BreadcrumbItem>{section}</BreadcrumbItem>
          </Breadcrumb>
        )}
      </div>
    </div>
  );
}
