import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Separator } from '@/components/ui/separator';
import { Sheet, SheetContent, SheetFooter, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Camera, Circle, Loader2, Mic, RefreshCw, Pause, Save, StopCircle, X } from 'lucide-react';
import { formatTimeMMSS } from '@/lib/utils';
import { useAuthStore } from '@/store/authStore';
import { useObservationStore } from '@/store/observationStore';
import { usePhotoStore } from '@/store/photoStore';
import { useRecordingStore } from '@/store/recordingStore';
import { useEffect, useState, Fragment } from 'react';
import { CurrentMedia, CurrentPhoto, CurrentVoice } from '@/types/observation';
import { useNavigate } from 'react-router-dom';
import { AudioPlayer } from "@/features/viewers/Audio";


function CurrentVoiceDisplay({
  recording,
  index,
  regenerateDraftRecording,
  removeRecording
}: {
  recording: CurrentVoice;
  index: number;
  regenerateDraftRecording: (id: number) => void;
  removeRecording: (id: string) => void
}) {
  return (
    <div key={recording.id} className="py-2 mb-2">
      <div className="flex items-center justify-between mb-1">
        <h3 className="font-semibold">Recording {index + 1}</h3>
        <div className="flex items-center gap-1">
          {recording.remoteId == null && !recording.failed && <Loader2 className="animate-spin h-4 w-4 mr-2" />}
          {recording.remoteId !== null && (
            <Button variant="ghost" size="icon" onClick={() => regenerateDraftRecording(Number(recording.id))}>
              <RefreshCw className="h-4 w-4" />
            </Button>
          )}
          <Button variant="destructive" size="icon" onClick={() => removeRecording(recording.id)}>
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      <AudioPlayer src={recording.audioUrl ?? ""}/>
      {recording.transcript == null && recording.remoteId != null ? (
        <p className="italic mb-2 text-muted-foreground">No transcript found</p>
      ) : (
        recording.transcript && (
          <Tabs defaultValue="summary" className="w-full mt-1">
            <TabsList>
              <TabsTrigger value="summary">Summary</TabsTrigger>
              <TabsTrigger value="transcript">Transcript</TabsTrigger>
            </TabsList>
            <TabsContent value="transcript">{recording.transcript}</TabsContent>
            <TabsContent value="summary">{recording.processedTranscript}</TabsContent>
          </Tabs>
        )
      )}
    </div>
  );
}

function CurrentPhotoDisplay({ 
  photo, 
  removePhoto,
  regenerateSummary
}: { 
  photo: CurrentPhoto; 
  removePhoto: (id: string) => void ;
  regenerateSummary: (id: number) => void
}) {
  return (
    <div key={photo.id} className="py-2 mb-2 w-full">
      <div className="flex items-center justify-between mb-1">
        <img
          src={photo.url}
          alt={`Uploaded photo ${photo.id}`}
          className={`w-20 h-20 object-cover rounded ${
            photo.failed || photo.remoteId == null ? 'grayscale animate-pulse' : ''
          }`}
        />
        <div className="flex items-center gap-1 mb-auto">
          {photo.remoteId == null && !photo.failed && <Loader2 className="animate-spin h-4 w-4 mr-2" />}
          {photo.remoteId !== null && (
            <Button variant="ghost" size="icon" onClick={() => regenerateSummary(Number(photo.id))}>
              <RefreshCw className="h-4 w-4" />
            </Button>
          )}
          <Button variant="destructive" size="icon" onClick={() => removePhoto(photo.id)}>
            <X className="h-4 w-4" />
          </Button>
        </div>
      </div>
      {photo.llm_summary && (
        <>
          <h1 className="font-bold mt-2">Summary:</h1>
          <span>{photo.llm_summary}</span>
        </>
      )}
    </div>
  );
}

export function ObservationCreate() {
  const { userDomainConfig } = useAuthStore();
  const { isCreating, setIsCreating, createObservation } = useObservationStore();
  const [submitting, setSubmitting] = useState(false);
  const { currentPhotos, regenerateSummary, removePhoto, addPhotos } = usePhotoStore();
  const { 
    draftRecordingsSaved, draftRecordingStatus, draftRecordingUrl, draftRecordingTime, draftRecordingResumeTime,
    startRecording, pauseRecording, seekedRecording, createRecording, regenerateDraftRecording, removeRecording
  } = useRecordingStore();

  const [currentMedia, setCurrentMedia] = useState<CurrentMedia[]>([]);

  useEffect(() => {
    const photos = currentPhotos.map((p) => ({
      ...p,
      mediaType: 'photo'
    })) as CurrentMedia[];
    const voices = draftRecordingsSaved.map((v) => ({
      ...v,
      mediaType: 'recording'
    })) as CurrentMedia[];
    setCurrentMedia(
      [...voices, ...photos].sort((a, b) => {
        const dateA = new Date(a.uploaded_at).getTime();
        const dateB = new Date(b.uploaded_at).getTime();
        return dateA - dateB;
      })
    );
  }, [currentPhotos, draftRecordingsSaved]);

  const navigate = useNavigate();

  const onCreate = async () => {
    setSubmitting(true);
    const response = await createObservation();
    if (response && response.inspection) {
      navigate(`/${userDomainConfig.urlChronicle}/${response.inspection.id}`);
    }
    setSubmitting(false);
  };

  return (
    <Sheet open={isCreating} onOpenChange={setIsCreating}>
      <SheetContent className="w-screen sm:w-[540px] flex flex-col px-0">
        <SheetHeader className="px-4">
          <SheetTitle>New Observation</SheetTitle>
          <Separator className="mt-4" />
        </SheetHeader>

        <div className="flex flex-col overflow-y-auto flex-1 px-4">
          {currentMedia.map((media, index) => (
            <Fragment key={media.id}>
              {media.mediaType === 'recording' ? (
                <CurrentVoiceDisplay
                  recording={media}
                  index={index}
                  regenerateDraftRecording={regenerateDraftRecording}
                  removeRecording={removeRecording}
                />
              ) : (
                <div className="flex flex-wrap gap-2">
                  <CurrentPhotoDisplay
                    photo={media}
                    regenerateSummary={regenerateSummary}
                    removePhoto={removePhoto}
                  />
                </div>
              )}
              {index !== currentMedia.length - 1 && <Separator className="my-4" />}
            </Fragment>
          ))}
        </div>

        <SheetFooter className="px-4">
          <div className="w-full">
            <Separator className="mb-4" />
            <div className="flex flex-col gap-2 mb-2">
              {draftRecordingStatus === 'review' && (
                <AudioPlayer 
                  src={draftRecordingUrl ?? ""}
                  onSeeked={seekedRecording} 
                  onPause={seekedRecording} 
                  onTimeUpdate={seekedRecording} 
                />
              )}
            </div>
            <div className="flex gap-2">
              {draftRecordingStatus === 'edit' && (
                <Button
                  variant="secondary"
                  onClick={() => pauseRecording()}
                  className="flex-1"
                >
                  <Pause className="h-4 w-4 mr-2" />
                  {`Pause: ${formatTimeMMSS(draftRecordingTime)}`} 
                </Button>
              )}
              {draftRecordingStatus  === 'review' && (
                <Button
                  variant="destructive"
                  onClick={() => startRecording()}
                  className="flex-1"
                >
                  <Circle className="h-4 w-4 mr-2" />
                  {`Resume: ${formatTimeMMSS(draftRecordingResumeTime)}`} 
                </Button>
              )}
              {(draftRecordingStatus === 'edit' || draftRecordingStatus === 'review') && (
                <Button
                  onClick={() => createRecording()}
                  disabled={false}
                  className="flex-1"
                >
                  <StopCircle className="h-4 w-4 mr-2" />
                  Create Recording
                </Button>
              )}
              {draftRecordingStatus === 'wait' && (
                <Button disabled={true} className="flex-1 flex items-center justify-center space-x-2">
                  <div className="relative w-16 h-2 bg-gray-300 rounded overflow-hidden">
                    {/* Skipping circle */}
                    <div className="absolute w-2 h-2 bg-blue-500 rounded-full animate-skip"></div>
                  </div>
                  <span>Processing</span>
                </Button>
              )}
              {draftRecordingStatus === 'inactive' && (
                <Button
                  variant='destructive'
                  onClick={() => startRecording()}
                  disabled={submitting}
                  className="flex-1"
                >
                  <Mic className="h-4 w-4 mr-2" />
                  'Start Recording'
                </Button>
              )}
              {draftRecordingStatus === 'inactive' && (
                <Label htmlFor="photo-upload"  className={`cursor-pointer flex-1 ${submitting ? 'pointer-events-none opacity-50' : ''}`}>
                  <Button variant="secondary" className="w-full" asChild>
                    <span>
                      <Camera className="h-4 w-4 mr-2" />
                      Take Photos
                      <Input
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        multiple
                        className="hidden"
                        onChange={(e) => addPhotos(e.target.files)}
                      />
                    </span>
                  </Button>
                </Label>
              )}
            </div>
            <Button
              onClick={onCreate}
              className="w-full mt-4"
              disabled={
                submitting ||
                draftRecordingStatus !== 'inactive' ||
                (draftRecordingsSaved.length + currentPhotos.length) == 0
              }
            >
              {submitting && <Loader2 className="animate-spin h-4 w-4 mr-2" />}
              {!submitting && <Save className="h-4 w-4 mr-2" />}
              Create Observation
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  );
}