import { api } from './authStore';
import { toast } from 'sonner';
import { Inspection } from '@/store/inspectionStore';
import { createPersistentStore } from '@/lib/utils';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';

export const PROJECT_ALIASES = ['projects', 'patients']

export interface Project {
  id: number;
  title: string | null;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  inspections: Inspection[];
}

interface ProjectState {
  projects: Project[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;

  setSearchQuery: (query: string) => void;
  fetchProjects: (page?: number, search?: string) => Promise<void>;
  createProject: (title: string, description: string) => Promise<void>;
  updateProject: (projectId: number, title: string, description: string) => Promise<void>;
  deleteProject: (projectId: number) => Promise<void>;
  updateTitle: (projectId: number, title: string) => Promise<void>;
  updateDescription: (projectId: number, description: string) => Promise<void>;
  generateProjectSummary: (projectId: number) => Promise<void>;
  retry: () => Promise<void>;
}

export const useProjectStore = createPersistentStore<ProjectState>('projectData', (set, get) => {

  const getBaseDomainUrl = async (): Promise<string> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return `/protected/${authStore.userDomainConfig.urlCase}`;
  };

  return {
    projects: [],
    isLoading: false,
    error: null,
    searchQuery: '',
    searchDebounceTimeout: null,

    setSearchQuery: (query) => {
      set({ searchQuery: query });
      const searchDebounceTimeout = get().searchDebounceTimeout;
      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      const timeout = setTimeout(() => {
        get().fetchProjects(1, query);
      }, 300);
      set({ searchDebounceTimeout: timeout });
    },

    fetchProjects: async () => {
      set({ isLoading: true, error: null });
      try {
        const { searchQuery } = get();
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/list`, {
          params: {
            query: searchQuery,
          },
        });
        set({
          projects: response.data.projects,
          isLoading: false
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Projects';
        set({
          error: errorMessage,
          isLoading: false
        });
        toast.error(errorMessage);
      }
    },

    createProject: async (title: string, description: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.post(`${baseDomainUrl}/create`, { title, description });
        toast.success('Project created successfully');
        get().fetchProjects();
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error creating project';
        toast.error(errorMessage);
      }
    },

    updateProject: async (projectId: number, title: string, description: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${projectId}`, { title, description });
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === projectId ? { ...project, title, description } : project
          )
        }));
        toast.success('Project updated successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error updating project';
        toast.error(errorMessage);
      }
    },

    deleteProject: async (projectId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.delete(`${baseDomainUrl}/${projectId}`);
        set((state) => ({
          projects: state.projects.filter((project) => project.id !== projectId)
        }));
        toast.success('Project deleted successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error deleting project';
        toast.error(errorMessage);
      }
    },

    generateProjectSummary: async (projectId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${projectId}/generate`);
        set((state) => ({
          projects: state.projects.map((project) =>
            project.id === projectId
              ? { ...project, title: response.data.title, description: response.data.description }
              : project
          )
        }));
        toast.success('Project summary generated successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error generating project summary';
        toast.error(errorMessage);
      }
    },

    retry: async () => await get().fetchProjects(),

    updateTitle: async (projectId: number, title: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${projectId}/updateTitle`, { title });
        set((state) => ({
          projects: state.projects.map((project) => (project.id === projectId ? { ...project, title } : project))
        }));
      } catch (error) {
        toast(`Error updating project title`);
      }
    },

    updateDescription: async (projectId: number, description: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${projectId}/updateDescription`, { description });
        set((state) => ({
          projects: state.projects.map((project) => (project.id === projectId ? { ...project, description } : project))
        }));
      } catch (error) {
        toast(`Error updating project description`);
      }
    }
  }
});

export const useFilteredProjects = () => {
  const { projects } = useProjectStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return projects.filter((project) => {
      const title = project.title?.toLowerCase() ?? '';
      const description = project.description?.toLowerCase() ?? '';
      const createdAt = new Date(project.createdAt);

      if (filterTab && filterTab !== '' && !PROJECT_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !title.includes(lowerInclude) && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && (title.includes(lowerExclude) || description.includes(lowerExclude))) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [projects, filters]); 
  
};
