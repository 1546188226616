import { api } from './authStore';
import { toast } from 'sonner';
import { Observation } from '@/types/observation';
import { usePhotoStore } from './photoStore';
import { useRecordingStore } from './recordingStore';
import { Inspection, useInspectionStore } from '@/store/inspectionStore';
import { Project, useProjectStore } from '@/store/projectStore';
import { createPersistentStore } from '@/lib/utils';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';

export const OBSERVATION_ALIASES = ['observations']

interface CreateObservationResponse {
  projects: Project[];
  inspection: Inspection;
  observation: Observation;
}

interface ObservationState {
  observations: Observation[];
  // FIXME: make this algebraic
  isCreating: boolean;
  isLinking: boolean;

  parentSiteId: number | undefined;
  parentProjectId: number | undefined;
  // FIXME: no good
  searchQuery: string;
  isLoading: boolean;
  error: string | null;
  searchDebounceTimeout: NodeJS.Timeout | null;

  setIsCreating: (isCreating: boolean, parentSiteId?: number, parentProjectId?: number) => void;
  setIsLinking: (isLinking: boolean, parentSiteId?: number) => void;

  setSearchQuery: (query: string) => void;

  updateTitle: (observationId: number, title: string) => Promise<void>;
  updateDescription: (observationId: number, description: string) => Promise<void>;

  fetchObservations: () => Promise<void>;
  deleteObservation: (observationId: number) => Promise<void>;
  generateSummary: (observationId: number) => Promise<void>;
  createObservation: () => Promise<CreateObservationResponse>;
  retry: () => Promise<void>;
}

export const useObservationStore = createPersistentStore<ObservationState>('observationData', (set, get) => {

  const getBaseDomainUrl = async (): Promise<string> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return `/protected/${authStore.userDomainConfig.urlObservation}`;
  };

  return {
    observations: [],
    isCreating: false,
    isLinking: false,
    parentSiteId: undefined,
    parentProjectId: undefined,
    searchQuery: '',
    isLoading: false,
    error: null,
    searchDebounceTimeout: null,

    setIsCreating: (isCreating, parentSiteId?, parentProjectId?) =>
      set({ isCreating, parentProjectId, parentSiteId, isLinking: false }),

    // if parent is undefined linking must be closed
    setIsLinking: (isLinking, parentSiteId?) => set({ isLinking, parentSiteId, isCreating: false }),

    setSearchQuery: (query) => {
      set({ searchQuery: query });
      const searchDebounceTimeout = get().searchDebounceTimeout;
      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      const timeout = setTimeout(() => {
        get().fetchObservations();
      }, 300);
      set({ searchDebounceTimeout: timeout });
    },

    fetchObservations: async () => {
      set({ isLoading: true, error: null });
      try {
        const { searchQuery } = get();
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/list`, {
          params: {
            query: searchQuery,
          },
        });
        set({
          observations: response.data.observations || [],
          isLoading: false,
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Observations';
        set({
          error: errorMessage,
          isLoading: false
        });
        toast.error(errorMessage);
      }
    },
    

    deleteObservation: async (observationId) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.delete(`${baseDomainUrl}/${observationId}`);
        if (response.status === 200) {
          set((state) => ({
            observations: state.observations.filter((obs) => obs.id !== observationId),
            selectedObs: null
          }));
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        toast(`Error deleting observation ${observationId}`);
      }
    },

    generateSummary: async (observationId) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/${observationId}/generate`);
        if (response.status === 200) {
          const { title, description, voices, photos } = response.data.data;
          set((state) => ({
            observations: state.observations.map((obs) =>
              obs.id === observationId ? { ...obs, title, description, voices, photos } : obs
            )
          }));
          // HACKY RE UPDATE
          const inspectionStore = useInspectionStore.getState();
          const recordingStore = useRecordingStore.getState();
          const photoStore = usePhotoStore.getState();
          await Promise.all([
            inspectionStore.fetchInspections(), 
            recordingStore.fetchRecordings(),
            photoStore.fetchPhotos(),
          ]);
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        toast(`Error generating report`);
      }
    },

    createObservation: async () => {
      try {
        const photoStore = usePhotoStore.getState();
        const photoIds = photoStore.currentPhotos
          .map((photo) => photo.remoteId)
          .filter((id): id is number => id !== null);
        const voiceStore = useRecordingStore.getState();
        const voiceIds = voiceStore.draftRecordingsSaved
          .map((recording) => recording.remoteId)
          .filter((id): id is number => id !== null);
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/create`, {
          description: '',
          photoIds,
          voiceIds,
          parentSiteId: get().parentSiteId,
          parentProjectId: get().parentProjectId
        });
        set({ isCreating: false, parentSiteId: undefined, parentProjectId: undefined });
        voiceStore.draftRecordingsSaved = [];
        photoStore.currentPhotos = [];

        // FIXME
        const inspectionStore = useInspectionStore.getState();
        const projectStore = useProjectStore.getState();
        const recordingStore = useRecordingStore.getState();
        await Promise.all([
          get().fetchObservations(),
          inspectionStore.fetchInspections(),
          projectStore.fetchProjects(),
          recordingStore.fetchRecordings(),
          photoStore.fetchPhotos(),
        ]);

        return response.data;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        console.error(error);
        toast(`Error creating observation`);
      }
    },

    retry: async () => await get().fetchObservations(),

    updateTitle: async (observationId: number, title: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${observationId}/updateTitle`, { title });
        set((state) => ({
          observations: state.observations.map((obs) => (obs.id === observationId ? { ...obs, title } : obs))
        }));
      } catch (error) {
        toast(`Error updating observation title`);
      }
    },

    updateDescription: async (observationId: number, description: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${observationId}/updateDescription`, { description });
        set((state) => ({
          observations: state.observations.map((obs) => (obs.id === observationId ? { ...obs, description } : obs))
        }));
      } catch (error) {
        toast(`Error updating observation description`);
      }
    }
  }
});

export const useFilteredObservations = () => {
  const { observations } = useObservationStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return observations.filter((observation) => {
      const title = observation.title?.toLowerCase() ?? '';
      const description = observation.description?.toLowerCase() ?? '';
      const createdAt = new Date(observation.createdAt);

      if (filterTab && filterTab !== '' && !OBSERVATION_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !title.includes(lowerInclude) && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && (title.includes(lowerExclude) || description.includes(lowerExclude))) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [observations, filters]); 
  
};
