import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useAuthStore } from '@/store/authStore.ts';
import { useSearchStore } from '@/store/searchStore.ts';

export function LayoutSidebar() {
  const navigate = useNavigate();
  const { userTabs } = useAuthStore();
  const { isCurrentTab, resetInputValue, setInputValue } = useSearchStore();

  return (
    <nav className="space-y-1 w-full">
      <div 
        className="mt-4 px-2 pb-4 text-sm font-semibold cursor-pointer"
        onClick={() => resetInputValue(navigate)}
      >
        Home
      </div>

      {/* Tabs Heading */}
      <div className="mt-4 px-2 pb-1 text-sm font-semibold text-muted-foreground">
        Tabs
      </div>

      {/* Render User Tabs */}
      <div className="space-y-1">
        {userTabs.map(
          (
            tab: { label: string; labelMobile: string; searchFilter: string },
            index: number
          ) => (
            <div
              key={index}
              className={cn(
                'flex items-center px-3 py-2 space-x-3 rounded-md cursor-pointer',
                isCurrentTab(tab.searchFilter)
                  ? 'bg-muted text-primary border-l-4 border-primary'
                  : 'hover:bg-accent text-muted-foreground'
              )}
              onClick={() => {
                setInputValue(tab.searchFilter, navigate)
              }}
            >
              {/* Icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 text-muted-foreground"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3 7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2V7z"
                />
              </svg>

              {/* Label */}
              <span className="truncate text-sm md:hidden">
                {tab.labelMobile}
              </span>
              <span className="truncate text-sm hidden md:inline">
                {tab.label}
              </span>
            </div>
          )
        )}
      </div>
    </nav>
  );
}
