import { type ClassValue, clsx } from 'clsx';
import { useEffect } from 'react';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export function titleCase(str: string): string {
  return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatTime(seconds: number) {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
}

export function formatTimeMMSS (timeInSeconds: number) {
  const wholeSeconds = Math.floor(timeInSeconds);
  const minutes = Math.floor(wholeSeconds / 60);
  const seconds = wholeSeconds % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

export function formatDate(date: Date): string {
  const today = new Date();
  const isToday =
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  if (isToday) {
    return date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit'
    });
  } else {
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  }
}

import { create, StateCreator, StoreApi, UseBoundStore } from 'zustand';
import { persist, PersistOptions } from 'zustand/middleware';

type CreatePersistentStoreOptions<T> = Omit<PersistOptions<T>, 'name'> & {
  name?: string;
};

// Define a utility function with generic types
export const createPersistentStore = <T>(
  name: string,
  storeConfig: StateCreator<T>,
  options: CreatePersistentStoreOptions<T> = {}
): UseBoundStore<StoreApi<T>> => create(persist<T>(storeConfig, { name, ...options }));

export function useDebounceWithLoading<T>(value: T, delay = 300): { debouncedValue: T; isLoading: boolean } {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return { debouncedValue, isLoading };
}
