import { api } from './authStore';
import { toast } from 'sonner';
import { Observation } from '@/types/observation';
import { createPersistentStore } from '@/lib/utils';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';

export const INSPECTION_ALIASES = ['inspections', 'consults']

export interface Inspection {
  id: number;
  title: string | null;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  observations: Observation[];
}

interface InspectionState {
  inspections: Inspection[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;

  setSearchQuery: (query: string) => void;
  fetchInspections: (page?: number, search?: string) => Promise<void>;
  createInspection: (projectId: number, observationIds: number[]) => Promise<void>;

  updateTitle: (inspectionId: number, title: string) => Promise<void>;
  updateDescription: (inspectionId: number, description: string) => Promise<void>;

  deleteInspection: (inspectionId: number) => Promise<void>;
  generateSummary: (inspectionId: number) => Promise<void>;

  linkObservation: (inspectionId: number, observationId: number) => Promise<void>;
  retry: () => Promise<void>;
}

export const useInspectionStore = createPersistentStore<InspectionState>('inspectionData', (set, get) => {

  const getBaseDomainUrl = async (): Promise<string> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return `/protected/${authStore.userDomainConfig.urlChronicle}`;
  };

  return {
    inspections: [],
    isLoading: false,
    error: null,
    searchQuery: '',
    searchDebounceTimeout: null,

    setSearchQuery: (query) => {
      set({ searchQuery: query });
      const searchDebounceTimeout = get().searchDebounceTimeout;
      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      const timeout = setTimeout(() => {
        // Note: This needs the projectId, so we'll need to store currentProjectId in state
        // or handle this differently in the component
        get().retry();
      }, 300);
      set({ searchDebounceTimeout: timeout });
    },

    fetchInspections: async () => {
      set({ isLoading: true, error: null });
      try {
        const { searchQuery } = get();
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/list`, {
          params: {
            query: searchQuery,
          },
        });
        set({
          inspections: response.data.siteInspections,
          isLoading: false
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Inspections';
        set({
          error: errorMessage,
          isLoading: false
        });
        toast.error(errorMessage);
      }
    },

    createInspection: async (projectId: number, observationIds: number[]) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/generate`, {
          projectId,
          observationIds
        });
        if (response.status == 200) {
          toast.success('Site inspection created successfully');
          get().fetchInspections(projectId);
        } else {
          toast.error('Unable to generate inspection summary. Please try again.');
        }
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error creating inspection';
        toast.error(errorMessage);
      }
    },

    updateTitle: async (inspectionId: number, title: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${inspectionId}/updateTitle`, { title });
        set((state) => ({
          inspections: state.inspections.map((inspection) =>
            inspection.id === inspectionId ? { ...inspection, title } : inspection
          )
        }));
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error updating inspection title';
        toast.error(errorMessage);
      }
    },
    updateDescription: async (inspectionId: number, description: string) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.put(`${baseDomainUrl}/${inspectionId}/updateDescription`, { description });
        set((state) => ({
          inspections: state.inspections.map((inspection) =>
            inspection.id === inspectionId ? { ...inspection, description } : inspection
          )
        }));
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error updating inspection description';
        toast.error(errorMessage);
      }
    },

    deleteInspection: async (inspectionId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.delete(`${baseDomainUrl}/${inspectionId}`);
        set((state) => ({
          inspections: state.inspections.filter((inspection) => inspection.id !== inspectionId)
        }));
        toast.success('Inspection deleted successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error deleting inspection';
        toast.error(errorMessage);
      }
    },

    generateSummary: async (inspectionId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${inspectionId}/generate`);
        const { title, description } = response.data;
        set((state) => ({
          inspections: state.inspections.map((inspection) =>
            inspection.id === inspectionId ? { ...inspection, title, description } : inspection
          )
        }));
        toast.success('Inspection summary generated successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error generating inspection summary';
        toast.error(errorMessage);
      }
    },

    retry: async () => {
      const state = get();
      if (state.inspections.length > 0) {
        // Use the project ID from the first inspection as a fallback
        const projectId = state.inspections[0].projectId;
        await get().fetchInspections(projectId);
      }
    },

    linkObservation: async (inspectionId: number, observationId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        await api.post(`${baseDomainUrl}/${inspectionId}/link`, { observationId });
        toast.success('Observation linked to inspection successfully');
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Error linking observation to inspection';
        toast.error(errorMessage);
      }
    }
  }
});

export const useFilteredInspections = () => {
  const { inspections } = useInspectionStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return inspections.filter((inspection) => {
      const title = inspection.title?.toLowerCase() ?? '';
      const description = inspection.description?.toLowerCase() ?? '';
      const createdAt = new Date(inspection.createdAt);

      if (filterTab && filterTab !== '' && !INSPECTION_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !title.includes(lowerInclude) && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && (title.includes(lowerExclude) || description.includes(lowerExclude))) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [inspections, filters]); 
  
};
