import { useEffect } from 'react';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useSearchResult } from '@/store/searchResultStore';
import { useFilteredProjects } from '@/store/projectStore';
import { useFilteredReports } from '@/store/reportStore';
import { useFilteredInspections } from '@/store/inspectionStore';
import { useFilteredObservations } from '@/store/observationStore';
import { useFilteredRecordings } from '@/store/recordingStore';
import { useFilteredPhotos } from '@/store/photoStore';
import { ListEntry } from './ExploreEntry';
import { useNavigate } from 'react-router-dom';

export function ExploreSearchResults() {
  const { setFilteredResults, setCurrentItem } = useSearchResult();
  const navigate = useNavigate();
  const { toggleItem, isItemSelected, clearSelectedItems } = useSelectedItemStore();

  const projects = useFilteredProjects();
  const reports = useFilteredReports();
  const inspections = useFilteredInspections();
  const observations = useFilteredObservations();
  const recordings = useFilteredRecordings();
  const photos = useFilteredPhotos();

  // Combine and set results in the search result store
  useEffect(() => {
    setFilteredResults({
      projects,
      reports,
      inspections,
      observations,
      recordings,
      photos
    });
  }, [projects, reports, inspections, observations, recordings, photos, setFilteredResults]);

  const { paginatedResults } = useSearchResult();

  return (
    <div className="overflow-y-auto pb-20">
      {paginatedResults.length === 0 ? (
        <div className="w-full text-center mt-6 text-sm text-muted-foreground">
          No results found.
        </div>
      ) : (
        <ul className="divide-y">
        {paginatedResults.map((result) => (
          <ListEntry
            key={result.uuid} 
            title={result.title || `${result.type} #${result.id}`}
            description={result.description}
            date={new Date(result.createdAt)}
            checked={isItemSelected({
              remoteId: result.id,
              itemType: result.itemType
            })}
            onCheckedChange={() => toggleItem({ remoteId: result.id, itemType: result.itemType })}
            hasAudio={result.type === 'Voice'}
            hasPhotos={result.type === 'Photo'}
            onClick={() => {
              clearSelectedItems();
              setCurrentItem(result);
              navigate(result.uri, {state: { from: [result.type] }});
            }}
          />
         ))}
      </ul>
      )}
    </div>
  );
}
