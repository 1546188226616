import { ImageIcon } from 'lucide-react';
import { AudioLines } from 'lucide-react';
import Markdown from 'react-markdown';
import { Checkbox } from '@/components/ui/checkbox';
import { formatDate } from '@/lib/utils';
import { Photo, Recording } from '@/types/media';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';

interface ListEntryProps {
  key: string;
  title: string;
  description: string | null;
  date: Date;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  hasAudio: boolean;
  hasPhotos: boolean;
  onClick: () => void;
}

export function ListEntry({
  key,
  title,
  description,
  date,
  checked,
  onCheckedChange,
  hasAudio,
  hasPhotos,
  onClick
}: ListEntryProps) {
  return (
    <li
      key={key}
      className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
    >
      <div className="flex items-center space-x-4 p-1">
        <Checkbox checked={checked} onClick={(e) => e.stopPropagation()} onCheckedChange={onCheckedChange} />
        <div className="flex-1 min-w-0 space-y-1 sm:space-y-0" onClick={onClick}>
          <div className="flex flex-col sm:flex-row sm:items-baseline">
            <h3 className="text-sm font-medium text-gray-900 truncate">{title}</h3>
            <div className="text-sm text-gray-500 truncate sm:ml-2 sm:flex-1">
              {description && (
                <div className="flex">
                  <p className="hidden sm:inline"> -&nbsp;</p>
                  <Markdown className="line-clamp-1">{description}</Markdown>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 sm:flex-row-reverse flex-col" onClick={onClick}>
          <time className="text-sm text-muted-foreground whitespace-nowrap">{formatDate(date)}</time>
          <div className="flex items-center gap-1">
            {hasAudio && <AudioLines className="h-3 w-3" />}
            {hasPhotos && <ImageIcon className="h-3 w-3" />}
            {/* {hasItems && <FileIcon className="h-3 w-3" />} */}
          </div>
        </div>
      </div>
    </li>
  );
}

export function RecordingEntry({ key, onClick, recording }: { key: string, onClick: () => void, recording: Recording }) {
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ListEntry
      key={key}
      title={'Recording'}
      description={recording.transcript}
      date={new Date(recording.uploaded_at)}
      checked={isItemSelected({
        remoteId: recording.id,
        itemType: 'recording'
      })}
      onCheckedChange={() => toggleItem({ remoteId: recording.id, itemType: 'recording' })}
      hasAudio={true}
      hasPhotos={false}
      onClick={onClick}
    />
  );
}

export function PhotoEntry({ key, onClick, photo }: { key: string, onClick: () => void, photo: Photo }) {
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ListEntry
      key={key}
      title={'Photo'}
      description={photo.llm_summary}
      date={new Date(photo.uploaded_at)}
      checked={isItemSelected({
        remoteId: photo.id,
        itemType: 'photo'
      })}
      onCheckedChange={() => toggleItem({ remoteId: photo.id, itemType: 'photo' })}
      hasAudio={false}
      hasPhotos={true}
      onClick={onClick}
    />
  );
}