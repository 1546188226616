import React, { useEffect } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  Outlet
} from 'react-router-dom';
import { useAuthStore } from './store/authStore';
import Login from '@/features/auth/Login';
import Layout from '@/components/layouts/Layout';
import LayoutHeader from '@/components/layouts/LayoutHeader';
import LayoutTabs from '@/components/layouts/LayoutTabs';
import LayoutDetails from './components/layouts/LayoutDetails';
import { DetailsChronicle } from './pages/details/DetailsChronicle';
import { DetailsObservation } from './pages/details/DetailsObservation';
import { DetailsCase } from './pages/details/DetailsCase';
import { DetailsPhoto } from './pages/details/DetailsPhoto';
import { DetailsRecording } from './pages/details/DetailsRecording';
import { DetailsArtifact } from './pages/details/DetailsArtifact';
import { DOMAIN_CONFIGS } from '@/lib/configDomain';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthStore();
  if (loading) return <div>Loading...</div>;
  if (!user) return <Navigate to="/login" replace />;
  return <>{children}</>;
}

function AppLayout() {
  return <Outlet />;
}

function App() {
  const { checkAuth, user, loading } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <div>Loading...</div>;

  const domainKey = user?.userType?.toLowerCase() || 'construction';
  const domainConfig = DOMAIN_CONFIGS[domainKey];

  const router = createBrowserRouter([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        { path: 'login', element: <Login /> },
        {
          path: `/explore`,
          element: (
            <ProtectedRoute>
              <LayoutHeader />
            </ProtectedRoute>
          ),
          children: [
            {
              element: <Layout />,
              children: [{ index: true, element: <LayoutTabs /> }]
            }
          ]
        },
        {
          path: `/${domainConfig.urlPrefix}`,
          element: (
            <ProtectedRoute>
              <LayoutHeader />
            </ProtectedRoute>
          ),
          children: [
            {
              element: <Layout />,
              children: [
                {
                  element: <LayoutDetails />,
                  children: [
                    { path: `${domainConfig.objectNames.case}/:id`, element: <DetailsCase /> },
                    { path: `${domainConfig.objectNames.artifact}/:id`, element: <DetailsArtifact /> },
                    { path: `${domainConfig.objectNames.chronicle}/:id`, element: <DetailsChronicle /> },
                    { path: `${domainConfig.objectNames.observation}/:id`, element: <DetailsObservation /> },
                    { path: `${domainConfig.objectNames.recording}/:id`, element: <DetailsRecording /> },
                    { path: `${domainConfig.objectNames.photo}/:id`, element: <DetailsPhoto /> }
                  ]
                }
              ]
            }
          ]
        },
        { path: '/', element: <Navigate to={`/explore`} replace /> },
        { path: '*', element: <Navigate to={`/explore`} replace /> }
      ]
    },
  ],
  {
    future: {
      v7_startTransition: true
    }
  }
);

  return <RouterProvider router={router} />;
}

export default App;
