import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2, Search, SlidersHorizontal, X } from 'lucide-react';
import { Input } from '@/components/ui/input.tsx';
import { useSearchStore } from '@/store/searchStore';
import { useDebounceWithLoading } from '@/lib/utils';

export function ExploreSearchBar() {
  const { query, setQuery, filters, setFilters, buildQueryString, inputValue, setInputValue, clearInputValue, getAllTabFilters } = useSearchStore();
  const navigate = useNavigate();
  const [isFilterPaneOpen, setIsFilterPaneOpen] = useState(false);
  const [filterPaneStyles, setFilterPaneStyles] = useState({});
  const searchInputRef = useRef<HTMLDivElement>(null);
  const filterPaneRef = useRef<HTMLDivElement>(null);
  const { debouncedValue, isLoading } = useDebounceWithLoading(inputValue, 300);
  const [tabOptions, setTabOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchTabOptions = async () => {
      const tabs = await getAllTabFilters();
      setTabOptions(tabs);
    };

    fetchTabOptions();
  }, [getAllTabFilters]);

  useEffect(() => {
    if (debouncedValue !== query) {
      setQuery(debouncedValue);
    }
  }, [debouncedValue, query, setQuery]);

  const handleFilterChange = (key: string, value: string) => {
    setFilters({ ...filters, [key]: value });
  };

  const applyFilters = () => {
    const newQuery = buildQueryString(filters);
    setInputValue(newQuery, navigate);
    setIsFilterPaneOpen(false);
  };

  const updateFilterPaneStyles = () => {
    if (searchInputRef.current) {
      const { offsetHeight, offsetWidth, offsetLeft } = searchInputRef.current;
      setFilterPaneStyles({
        top: offsetHeight + 4,
        left: offsetLeft,
        width: offsetWidth,
      });
    }
  };

  useEffect(() => {
    updateFilterPaneStyles();
    window.addEventListener('resize', updateFilterPaneStyles);

    const handleClickOutside = (event: MouseEvent) => {
      if (
        filterPaneRef.current &&
        !filterPaneRef.current.contains(event.target as Node) &&
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target as Node)
      ) {
        setIsFilterPaneOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', updateFilterPaneStyles);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <div className="relative w-full" ref={searchInputRef}>
        {isLoading ? (
          <Loader2 className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground animate-spin" />
        ) : (
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
        )}
        <Input
          type="search"
          placeholder="Search..."
          className="w-full pl-8 pr-10"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value, navigate)}
          noClearButton
        />
        {inputValue && (
          <X
            className="absolute right-6 top-2.5 h-4 w-4 mr-2 text-muted-foreground cursor-pointer"
            onClick={() => {
              clearInputValue(navigate);
            }}
          />
        )}
        <SlidersHorizontal
          className="absolute right-2 top-2.5 h-4 w-4 text-muted-foreground cursor-pointer"
          onClick={() => {
            setIsFilterPaneOpen(!isFilterPaneOpen);
            updateFilterPaneStyles();
          }}
        />
      </div>

      {isFilterPaneOpen && (
        <div
          ref={filterPaneRef}
          className="absolute bg-white p-4 border rounded shadow-md"
          style={{
            ...filterPaneStyles,
            position: 'absolute',
            zIndex: 1000,
          }}
        >
          <div className="flex flex-col space-y-4">
            <div className="flex items-center">
              <label className="text-sm text-gray-500 w-24">Tab:</label>
              <select
                className="w-full border rounded-md px-2 py-1 text-sm text-gray-500 "
                value={filters.tab || ''}
                onChange={(e) => handleFilterChange('tab', e.target.value)}
              >
                <option value="">Select Tab</option>
                {tabOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label className="text-sm text-gray-500 w-24">Include:</label>
              <Input
                type="text"
                placeholder="Include String"
                className="w-full"
                value={filters.include || ''}
                onChange={(e) => handleFilterChange('include', e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <label className="text-sm text-gray-500 w-24">Exclude:</label>
              <Input
                type="text"
                placeholder="Exclude String"
                className="w-full"
                value={filters.exclude || ''}
                onChange={(e) => handleFilterChange('exclude', e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <label className="text-sm text-gray-500 w-24">After:</label>
              <Input
                type="date"
                className="w-full"
                value={filters.after || ''}
                onChange={(e) => handleFilterChange('after', e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <label className="text-sm text-gray-500 w-24">Before:</label>
              <Input
                type="date"
                className="w-full"
                value={filters.before || ''}
                onChange={(e) => handleFilterChange('before', e.target.value)}
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded"
              onClick={applyFilters}
            >
              Search
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
}
