import { useNavigate } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useAuthStore } from '@/store/authStore.ts';
import { useSearchStore } from '@/store/searchStore.ts';

export function ExploreNavTabs() {
  const navigate = useNavigate();
  const { userTabs } = useAuthStore();
  const { isCurrentTab, setInputValue, isLayoutGlobal, isLayoutTabsSearching } = useSearchStore();

  return (
    <div className="bg-stone-50 border-b">
      {/* Row of tabs */}
      <div className="flex flex-wrap gap-1 px-2 md:px-4 py-1 bg-stone-50 border-none">
        {userTabs.map((tab) => (
          <div
            key={tab.value}
            className={cn(
              'px-2 md:px-4 py-2 rounded-md cursor-pointer text-sm font-medium',
              isCurrentTab(tab.searchFilter)
                ? 'bg-muted text-primary border-b-2 border-primary'
                : 'hover:bg-accent text-muted-foreground'
            )}
            onClick={() => {
              if (tab.searchFilter) {
                setInputValue(tab.searchFilter, navigate);
              }
            }}
          >
            <span className="block md:hidden">{tab.labelMobile}</span>
            <span className="hidden md:block">{tab.label}</span>
          </div>
        ))}
      </div>

      {isLayoutTabsSearching() && (
        <div className="px-2 sm:px-4 pb-2 text-xs text-muted-foreground bg-stone-50 w-full">
          Tab Search Activated - Searching matching tabs
        </div>
      )}

      {isLayoutGlobal() && (
        <div className="px-2 sm:px-4 pb-2 text-xs text-muted-foreground bg-stone-50 w-full">
          Global Search Activated - Searching all tabs
        </div>
      )}

    </div>
  );
}
