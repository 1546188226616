export enum UserType {
    CONSTRUCTION = 'CONSTRUCTION',
    MEDICAL = 'MEDICAL'
}
  
export const tabsConfig: Record<UserType, { value: string; isDefault: boolean; label: string; labelMobile: string; searchFilter: string}[]> = {
    [UserType.CONSTRUCTION]: [
        { value: 'projects', isDefault: true, label: 'Projects', labelMobile:  'Projects', searchFilter: 'tab:projects ' },
        { value: 'reports', isDefault: false, label: 'Reports', labelMobile:  'Reports', searchFilter: 'tab:reports ' },
        { value: 'site-inspection', isDefault: false, label: 'Site Inspections', labelMobile:  'Site', searchFilter: 'tab:inspections '},
        { value: 'observations', isDefault: false, label: 'Observations', labelMobile:  'Observations', searchFilter: 'tab:observations '},
        { value: 'media', isDefault: false, label: 'Files', labelMobile:  'Files', searchFilter: 'tab:files '}
    ],
    [UserType.MEDICAL]: [
        { value: 'patients', isDefault: true, label: 'Patients', labelMobile:  'Patients', searchFilter: 'tab:patients ' },
        { value: 'letters', isDefault: false, label: 'Letters', labelMobile:  'Letters', searchFilter: 'tab:letters ' },
        { value: 'consults', isDefault: false, label: 'Consults', labelMobile:  'Consults', searchFilter: 'tab:consults ' },
        { value: 'observations', isDefault: false, label: 'Observations', labelMobile:  'Obvs', searchFilter: 'tab:observations ' },
        { value: 'media', isDefault: false, label: 'Files', labelMobile:  'Files', searchFilter: 'tab:files ' }
    ]
};

export type DomainObjectNames = {
    case: string;
    artifact: string;
    chronicle: string;
    observation: string;
    recording: string;
    photo: string;
};
  
export type DomainConfig = {
    name: string;
    urlPrefix: string;
    objectNames: DomainObjectNames;
    urlCase: string;
    urlArtifact: string;
    urlChronicle: string;
    urlObservation: string;
    urlRecording: string;
    urlPhoto: string;
};

export const DISPLAY_PATH_REGEX = /^\/medical|construction\/(project|patient|report|letter|inspection|observation|consult|recording|voice|photo)\/\d+$/;
  
export const DOMAIN_CONFIGS: Record<string, DomainConfig> = {
    construction: {
        name: 'Construction',
        urlPrefix: 'construction',
        objectNames: {
            case: 'project',
            artifact: 'report',
            chronicle: 'inspection',
            observation: 'observation',
            recording: 'recording',
            photo: 'photo'
        },
        urlCase: 'construction/project',
        urlArtifact: 'construction/report',
        urlChronicle: 'construction/inspection',
        urlObservation: 'construction/observation',
        urlRecording: 'construction/recording',
        urlPhoto: 'construction/photo'
    },
    medical: {
        name: 'Medical',
        urlPrefix: 'medical',
        objectNames: {
            case: 'patient',
            artifact: 'letter',
            chronicle: 'consult',
            observation: 'observation',
            recording: 'recording',
            photo: 'photo'
        },
        urlCase: 'medical/patient',
        urlArtifact: 'medical/letter',
        urlChronicle: 'medical/consult',
        urlObservation: 'medical/observation',
        urlRecording: 'medical/recording',
        urlPhoto: 'medical/photo'
    }
}
  