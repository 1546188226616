import React, { useEffect, useRef, useState } from "react";
import { Play, Pause, VolumeX, Volume2 } from "lucide-react";

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs.toString().padStart(2, "0")}`;
};

export function AudioPlayer({ 
  src, 
  onSeeked, 
  onPause, 
  onTimeUpdate 
}: { 
  src: string;
  onSeeked?: (time: number) => void;
  onPause?: (time: number) => void;
  onTimeUpdate?: (time: number) => void;
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [showVolume, setShowVolume] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateTime = () => {
      setCurrentTime(audio.currentTime);
      if (onTimeUpdate) onTimeUpdate(audio.currentTime);
    };

    audio.addEventListener("timeupdate", updateTime);
    audio.addEventListener("pause", () => {
      setIsPlaying(false);
      if (onPause) onPause(audio.currentTime);
    });
    audio.addEventListener("play", () => setIsPlaying(true));

    return () => {
      audio.removeEventListener("timeupdate", updateTime);
      audio.removeEventListener("pause", () => setIsPlaying(false));
      audio.removeEventListener("play", () => setIsPlaying(true));
    };
  }, [onTimeUpdate, onPause]);

  const playPause = () => {
    const audio = audioRef.current;
    if (!audio) return;

    if (audio.paused) {
      audio.play().catch(console.error);
    } else {
      audio.pause();
    }
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(event.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
    setCurrentTime(newTime);
    if(onSeeked) onSeeked(newTime);
  };

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 0 : newVolume;
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
    }
    setIsMuted(!isMuted);
  };

  return (
    <div className="flex items-center gap-1 p-2 bg-gray-200 rounded-lg w-full relative">
      <audio 
        ref={audioRef} 
        src={src} 
        onLoadedMetadata={(e) => setDuration(e.currentTarget.duration)} 
      />

      <button onClick={playPause} className="p-2">
        {isPlaying ? <Pause size={24} fill="black" /> : <Play size={24} fill="black" />}
      </button>

      <span className="text-sm w-24 text-black">
        {formatTime(currentTime)} / {formatTime(duration)}
      </span>

      <input
        type="range"
        min="0"
        max={duration || 1}
        step="0.01"
        value={currentTime}
        onChange={handleSeek}
        className="flex-1 bg-gray-300 accent-black"
      />
    <div 
      className="relative flex items-center"
      onMouseEnter={() => setShowVolume(true)}
      onMouseLeave={() => setShowVolume(false)}
    >
      {showVolume && (
        <div 
          className="absolute right-0 flex items-center bg-white pl-2 pr-0 py-0 rounded-lg shadow-lg transition-all duration-300"
        >
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="w-24 accent-black"
          />
          {/* Duplicate button inside hover area to prevent flicker */}
          <button onClick={toggleMute} className="p-2 ml-2">
            {isMuted ? <VolumeX size={20} fill="black" /> : <Volume2 size={20} fill="black" />}
          </button>
        </div>
      )}

      {/* The original icon remains in place */}
      <button onClick={toggleMute} className="p-2">
        {isMuted ? <VolumeX size={20} fill="black" /> : <Volume2 size={20} fill="black" />}
      </button>
    </div>
    </div>
  );
}
