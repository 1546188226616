import { create } from 'zustand';
import { api } from './authStore';
import { toast } from 'sonner';
import { CurrentVoice } from '@/types/observation';
import { nanoid } from 'nanoid';
import { useSearchStore } from './searchStore';
import { useMemo } from 'react';
import { Recording } from '@/types/media';
import { audioBlob, combineAudioBlobs } from '@/lib/audio';

export const RECORDING_ALIASES = ['files', 'recordings', 'voices', 'auidos']

interface RecordingState {
  recordings: Recording[];
  isLoading: boolean;
  error: string | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;
  mediaRecorder: MediaRecorder | null;
  draftRecording: Blob | null;
  draftRecordingStatus: string;
  draftRecordingTime: number;
  draftRecordingResumeTime: number;
  draftRecordingUrl: string | null;
  draftRecordingsSaved: CurrentVoice[];
  timerInterval: NodeJS.Timeout | null;
  setSearchQuery: (query: string) => void;
  fetchRecordings: (page?: number, search?: string) => Promise<void>;
  startRecording: () => Promise<void>;
  pauseRecording: () => void;
  seekedRecording: (seekedTime: number) => void;
  generateDraftRecordingUrl: () => void;
  createRecording: () => void;
  removeRecording: (recordingId: string) => void;
  uploadRecording: (recordingId: string, blob: Blob) => Promise<void>;
  regenerateDraftRecording: (remoteRecordingId: number) => void;
  regenerateRecording: (recordingId: number) => void;
}

export const useRecordingStore = create<RecordingState>((set, get) => {

  const getBaseDomainUrl = async (): Promise<string> => {
    const { useAuthStore } = await import('./authStore');
    const authStore = useAuthStore.getState();

    // Wait until userInitialised is true
    while (!authStore.userInitialised) {
      await new Promise((resolve) => setTimeout(resolve, 50));
    }

    return `/protected/${authStore.userDomainConfig.urlRecording}`;
  };

  return {
    recordings: [],
    isLoading: false,
    error: null,
    searchQuery: '',
    searchDebounceTimeout: null,
    mediaRecorder: null,
    draftRecording: null,
    draftRecordingStatus: 'inactive',
    draftRecordingTime: 0,
    draftRecordingResumeTime: 0,
    draftRecordingUrl: null,
    draftRecordingsSaved: [],
    timerInterval: null,

    setSearchQuery: (query) => {
      set({ searchQuery: query });
      const searchDebounceTimeout = get().searchDebounceTimeout;
      if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
      const timeout = setTimeout(() => {
        get().fetchRecordings(1, query);
      }, 300);
      set({ searchDebounceTimeout: timeout });
    },

    fetchRecordings: async () => {
      set({ isLoading: true, error: null });
      try {
        const { searchQuery } = get();
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.get(`${baseDomainUrl}/list`, {
          params: {
            query: searchQuery,
          },
        });
        set({
          recordings: response.data.voices,
          isLoading: false
        });
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching Recordings';
        set({
          error: errorMessage,
          isLoading: false
        });
        toast.error(errorMessage);
      }
    },

    startRecording: async () => {
      try {
        const { draftRecordingResumeTime, mediaRecorder, timerInterval } = get();
    
        // Stop the existing MediaRecorder if active
        if (mediaRecorder && mediaRecorder.state !== "inactive") {
          mediaRecorder.stop();
        }
    
        // Clear any existing timers
        if (timerInterval) {
          clearInterval(timerInterval);
          set({ timerInterval: null });
        }
    
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const mimeType = "audio/mp4";
        const newMediaRecorder = new MediaRecorder(stream, { mimeType });

        newMediaRecorder.ondataavailable = async (event) => {
          if (event.data.size > 0) {
            const { draftRecording } = get();
            try {
              set({ draftRecordingStatus: 'wait' });
              const combinedRecording = draftRecording
                ? await combineAudioBlobs(draftRecording, draftRecordingResumeTime, event.data, mimeType)
                : await audioBlob(event.data, mimeType);
              set({ draftRecording: combinedRecording });
              get().generateDraftRecordingUrl();
              set({ timerInterval: null, draftRecordingStatus: 'review' });
            } catch (err) {
              console.error("Error combining audio blobs:", err);
              toast.error("Failed to process audio.");
            }
          }
        };
    
        newMediaRecorder.onstart = () => {
          const { timerInterval, draftRecordingResumeTime, draftRecordingTime } = get();
        
          if (timerInterval) clearInterval(timerInterval);
          set({
            draftRecordingTime: draftRecordingResumeTime || draftRecordingTime,
            draftRecordingResumeTime: 0,
          });
        
          const timer = setInterval(() => {
            set((state) => ({
              draftRecordingTime: state.draftRecordingTime + 1,
            }));
          }, 1000);
        
          set({
            draftRecordingStatus: 'edit',
            timerInterval: timer,
          });
        };
    
        newMediaRecorder.onstop = async () => {
          const { draftRecordingTime, timerInterval } = get();
          set({ draftRecordingResumeTime: draftRecordingTime });
          if (timerInterval) clearInterval(timerInterval);
          stream.getTracks().forEach((track) => track.stop());
        };
    
        set({ mediaRecorder: newMediaRecorder });
        newMediaRecorder.start();
      } catch (error) {
        toast.error("Error starting recording");
        console.error("Error starting recording:", error);
      }
    },
    
    pauseRecording: () => {
      const { mediaRecorder, timerInterval } = get();
      if (!mediaRecorder || mediaRecorder.state !== "recording") {
        toast.error("No active recording to pause.");
        return;
      }
      mediaRecorder.stop(); // Stop the recording
      if (timerInterval) clearInterval(timerInterval); // Stop the timer
    },

    seekedRecording: (seekedTime: number) => {
      set({ draftRecordingResumeTime: seekedTime });
    },
    
    generateDraftRecordingUrl: () => {
      const { draftRecording } = get();
      if (!draftRecording) {
        toast.error('No recording segments to playback.');
        return;
      }
      const draftRecordingUrl = URL.createObjectURL(draftRecording);
      set({ draftRecordingUrl });
    },

    createRecording: async () => {
      const { mediaRecorder, timerInterval } = get();

      if (mediaRecorder && mediaRecorder.state === "recording") {
        set({draftRecordingStatus: 'wait'});
        mediaRecorder.stop();
        await new Promise<void>((resolve) => {
          const interval = setInterval(() => {
            if (get().draftRecordingStatus !== 'wait') {
              clearInterval(interval);
              resolve();
            }
          }, 50);
        });
      }
    
      const { draftRecording } = get();
    
      if (!draftRecording) {
        toast.error('No recording available to create');
        return;
      }
    
      if (timerInterval) clearInterval(timerInterval);
    
      const newRecording = {
        id: nanoid(10),
        audioUrl: URL.createObjectURL(draftRecording),
        transcript: null,
        processedTranscript: null,
        remoteId: null,
        failed: false,
        uploaded_at: new Date(),
      } as CurrentVoice;
    
      set((state) => ({
        draftRecordingsSaved: [...state.draftRecordingsSaved, newRecording],
        draftRecordingStatus: 'inactive',
        draftRecording: null,
        draftRecordingUrl: null,
        draftRecordingTime: 0,
        draftRecordingResumeTime: 0,
        timerInterval: null,
      }));

      await get().uploadRecording(newRecording.id, draftRecording);
    
      toast.success('Recording created successfully');
    },

    removeRecording: (recordingId) =>
      set((state) => ({
        draftRecordingsSaved: state.draftRecordingsSaved.filter((r) => r.id !== recordingId)
      })),

    uploadRecording: async (recordingId, blob) => {
      try {
        const formData = new FormData();
        formData.append('audio', blob);
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/upload`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          maxContentLength: 1000000000,
          maxBodyLength: 1000000000
        });

        set((state) => ({
          draftRecordingsSaved: state.draftRecordingsSaved.map((r) =>
            r.id === recordingId
              ? {
                  ...r,
                  transcript: response.data.transcript,
                  processedTranscript: response.data.processedTranscript,
                  remoteId: response.data.voice_id,
                  failed: false
                }
              : r
          )
        }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set((state) => ({
          draftRecordingsSaved: state.draftRecordingsSaved.map((r) => (r.id === recordingId ? { ...r, failed: true } : r))
        }));
        toast(`Error processing recording`);
      }
    },

    regenerateDraftRecording: async (remoteRecordingId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${remoteRecordingId}/regenerate`);
        // TODO: use a map
        set((state) => ({
          draftRecordingsSaved: state.draftRecordingsSaved.map((r) =>
            r.remoteId === remoteRecordingId
              ? {
                  ...r,
                  transcript: response.data.transcript,
                  processedTranscript: response.data.processedTranscript,
                  failed: false
                }
              : r
          )
        }));
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        set((state) => ({
          draftRecordingsSaved: state.draftRecordingsSaved.map((r) =>
            r.remoteId === remoteRecordingId
              ? {
                  ...r,
                  failed: true
                }
              : r
          )
        }));
        toast(`Error regenerating draft recording`);
      }
    },

    regenerateRecording: async (recordingId: number) => {
      try {
        const baseDomainUrl = await getBaseDomainUrl();
        const response = await api.post(`${baseDomainUrl}/${recordingId}/regenerate`);
        // TODO: use a map
        set((state) => ({
          recordings: state.recordings.map((recording) =>
            recording.id === recordingId
              ? {
                  ...recording,
                  transcript: response.data.transcript,
                  processedTranscript: response.data.processedTranscript
                }
              : recording
          )
        }));
      } catch (error) {
        toast(`Error regenerating recording`);
      }
    }

  }
});

export const useFilteredRecordings = () => {
  const { recordings } = useRecordingStore();
  const { filters } = useSearchStore();

  return useMemo(() => {
    const lowerInclude = filters.include?.toLowerCase();
    const lowerExclude = filters.exclude?.toLowerCase();
    const afterDate = filters.after ? new Date(filters.after) : null;
    const beforeDate = filters.before ? new Date(filters.before) : null;
    const filterTab = filters.tab?.toLowerCase();

    return recordings.filter((recording) => {
      const description = recording.processedTranscript?.toLowerCase() ?? '';
      const createdAt = new Date(recording.uploaded_at);

      if (filterTab && filterTab !== '' && !RECORDING_ALIASES.some((alias) => alias.toLowerCase().includes(filterTab))) {
        return false;
      }

      if (lowerInclude && !description.includes(lowerInclude)) {
        return false;
      }

      if (lowerExclude && description.includes(lowerExclude)) {
        return false;
      }

      if (afterDate && createdAt < afterDate) {
        return false;
      }

      if (beforeDate && createdAt > beforeDate) {
        return false;
      }

      return true;
    });
  }, [recordings, filters]); 
  
};